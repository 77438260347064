/* App.css */
body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  overflow: hidden;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 1000;
}

.app-header h1 {
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  transition: color 0.3s;
}

.app-header h1:hover {
  color: #e0e0e0;
}

.App {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  margin-top: 60px; /* To offset the fixed header */
}

.panel {
  position: relative;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
}

.video-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-video video {
  object-fit: cover;
}

.overlay-text {
  position: absolute;
  color: #fff;
  text-align: center;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  animation: fadeIn 1s ease-in-out;
}

.blurred {
  backdrop-filter: blur(5px);
}

.download-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: linear-gradient(45deg, #8e2de2, #4a00e0);
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: transform 0.3s;
}

.download-button:hover {
  transform: scale(1.05);
}

.landscape .full-video {
  width: 100%;
  height: 100%;
}

.portrait .full-video {
  width: auto;
  height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Gradient Background */
.App {
  background: linear-gradient(to bottom, #8e2de2, #4a00e0);
}

/* Cross-fade Animation */
.panel {
  opacity: 0;
  animation: panelFadeIn 1s forwards;
}

.panel:nth-child(1) {
  animation-delay: 0s;
}

.panel:nth-child(2) {
  animation-delay: 0.5s;
}

.panel:nth-child(3) {
  animation-delay: 1s;
}

.panel:nth-child(4) {
  animation-delay: 1.5s;
}

.panel:nth-child(5) {
  animation-delay: 2s;
}

.panel:nth-child(6) {
  animation-delay: 2.5s;
}

.panel:nth-child(7) {
  animation-delay: 3s;
}

@keyframes panelFadeIn {
  to {
    opacity: 1;
  }
}
